import React from 'react'
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import { SizedText, Body } from '../../../components/elements/typography'


import Layout from '../../../layouts'
import Grid from '../../../components/elements/grid'

const WebFormsPage = ({ data }) => {


  const formsList = data.allFormsFormsYaml.nodes.map(i => (
    <li key={i.yamlId}><Link to={`/resources/forms/${i.name.replaceAll(' ', '-').toLowerCase()}`}>{i.name}</Link></li>
  ))

  return (
    <Layout isSubMenuOpen={false} openAccountLink={true}>
      <Grid.FullWidthSection styles={{
        overflow: `hidden`,
        alignItems: `center`,
        padding: `4rem 0`,
      }}>
      <Grid.Block start='2' span='12'>
          <h1 css={{fontFamily: `Proxima-nova`, textAlign: `center`, marginBottom: `4rem`}}>
            <SizedText as="span" smallSize="2.027" largeSize="3.653">Documents & Forms</SizedText>
          </h1>
      </Grid.Block>
      <Grid.Block start='2' span='12'>
        <ul>
          {formsList}
        </ul>
      </Grid.Block>
      </Grid.FullWidthSection>
    </Layout>
  )
}

export default WebFormsPage

export const pageQuery = graphql`
  query AllWebFormsQuery {
    allFormsFormsYaml {
      nodes {
        yamlId
        name
      }
    }
  }
`